import GeneralTextBlock from '@/components/GeneralTextBlock';
import Layout from '@/components/Layout/Layout';
import SEO from '@/components/SEO/Seo';
import SearchWidgetContainer from '@/components/SearchWidgetContainer';
import GeneralHeroSec from '@motokiki/shared/generalHeroSec';
import { graphql } from 'gatsby';
import React from 'react';

import './individualBrandsPage.scss';

interface Props {
  data: {
    strapi: {
      brand: {
        HeroSection: {
          activeLozenge: string;
          className: string;
          displayIcon: string;
          headerText: string;
          mainImg: {
            url: string;
            alternativeText: string;
          };
          headerPrefix: string;
          lozengeText: string;
          iconImg: {
            url: string;
          };
        };
        Seo: {
          description: string;
          title: string;
        };
        brandDescription: string;
        brandLogo: {
          url: string;
          alternativeText: string;
        };
        brandName: string;
      };
    };
  };
}

const individualBrandsPage = ({ data }: Props) => {
  const {
    brandName,
    HeroSection,
    Seo,
    brandDescription,
    brandLogo,
  } = data.strapi.brand;

  return (
    <>
      <SEO title={Seo.title} description={Seo.description} />
      <Layout>
        <GeneralHeroSec data={HeroSection} />
        <div className="brandsImgCont">
          <img
            className="brandLogo"
            src={brandLogo.url}
            alt={brandLogo.alternativeText}
          />
        </div>
        <div className="brandsTextCont">
          <GeneralTextBlock brandDescription={brandDescription} />
        </div>
        <SearchWidgetContainer />
      </Layout>
    </>
  );
};

export const BrandPages = graphql`
  query($id: ID!) {
    strapi {
      brand(id: $id, publicationState: PREVIEW) {
        HeroSection {
          activeLozenge
          className
          mainImg {
            url
            alternativeText
          }
          displayIcon
          headerPrefix
          headerText
          lozengeText
          iconImg {
            url
            alternativeText
          }
        }
        Seo {
          description
          title
        }
        brandDescription
        brandName
        brandLogo {
          url
          alternativeText
        }
      }
    }
  }
`;

export default individualBrandsPage;
